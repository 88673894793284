import React from 'react';
import LoginComp from '../Components/LoginComp';
import './css/New_password.css';
import swaap_logo from '../assets/swaap_logo.svg';
import email_icon from '../assets/email_icon.svg';
import eye_close from '../assets/eye.svg';
import { useNavigate } from 'react-router-dom';

const New_password = () => {
    const navigate = useNavigate();

    return (
        <div className='login_page'>
            <LoginComp />
            <div className='login_page_details' >
                <div className='Login_details'>
                    <div className='Login_header'>
                        <h1>Enter new password</h1>
                        <h3>Please enter new password</h3>
                    </div>
                    <img src={swaap_logo} alt='swaap Logo' />
                </div>
                <form onSubmit={()=>{navigate('/Login')}} >
                    <div className='password_field'>
                        <label>Enter New Password</label>
                        <div className='input_field'>
                            <input type='password' name='Password' placeholder='**********' required />
                            <img src={eye_close} alt='email icon' />
                        </div>
                    </div>
                    <div className='password_field'>
                        <label>Confirm Password</label>
                        <div className='input_field'>
                            <input type='password' name='Password' placeholder='**********' required />
                            <img src={eye_close} alt='email icon' />
                        </div>
                    </div>
                    <div className='submit_button'>
                        <button type='submit'>submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default New_password
