import "./css/Pagination.css";
import backward from "../assets/Backward.svg";
import forward from "../assets/Forward.svg";
import forwardFaded from "../assets/ForwardFaded.svg";
import backwardFaded from "../assets/BackwardFaded.svg";

export default function Pagination({ count, page, setPage, rowsPerPage }) {
    const pageCount = Math.ceil(count / rowsPerPage);

    return (
        <div className="eclipses-jell">
            <div className="nuked-cob">
                <div className="rewarn-eye">{(page - 1) * rowsPerPage + 1} -
                    <span className="docile-tale"> {page * rowsPerPage} </span>
                    <span className="tusked-porn">  of {count}</span>
                    <span className="tusked-porn"> Viewing</span>
                </div>
                <img
                    className="mavens-ben"
                    src={page === 1 ? backwardFaded : backward}
                    style={{ cursor: page === 1 ? '' : 'pointer' }}
                    alt=""
                    onClick={() => setPage(prev => page > 1 ? prev - 1 : prev)}
                />
                <img
                    className="nettles-gigs"
                    src={page === pageCount ? forwardFaded : forward}
                    style={{ cursor: page === pageCount ? '' : 'pointer' }}
                    alt=""
                    onClick={() => setPage(prev => page < pageCount ? prev + 1 : prev)}
                />
            </div>
        </div>
    )
}