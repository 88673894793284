import React, { useEffect, useState } from 'react';
import view from '../assets/View.svg';
import SearchBar from '../Components/SearchBar';
import Pagination from '../Components/Pagination';
import './css/Users.css';
import TopBar from '../Components/TopBar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import instance from '../utils/api';
import { useNavigate } from 'react-router-dom';
import Loading from '../assets/Loading.svg';


const Payments = () => {
    const [filteredData, setFilteredData] = useState([]);
    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [dialog, setDialog] = useState(false);
    const [dialogData, setDialogData] = useState();
    const [loading, setLoading] = useState(true);
    const rowsPerPage = 10;
    const startIndex = (page - 1) * rowsPerPage;

    const navigate = useNavigate();
    const spList = async () => {
        try {
            const response = await instance.get('/api/Admin/serviceProvidersActive');
            console.log(response);
            if (response.data.success === true) {


                // Corrected filter logic
                const filter = response.data.sp.filter((data) => data.sp_id); // Assuming you want to filter items with a truthy 'sp' field

                console.log("filter", filter);
                setData(filter); // Set the filtered data
                setFilteredData(filter); // Also set the filtered data
            }
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data.message);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        spList();
    }, [])

    console.log(data);
    console.log(filteredData);

    const dataToShow = filteredData?.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    return (
        <div>
            <TopBar title={"MemberShip Payments"} />
            {loading ?
                (
                    <div className="loading-spinner">
                        <img src={Loading} alt="loading" height={80} width={80} />
                    </div>
                ) :
                (<div className='_2wae'>
                    <div className="forget-hark">
                        <SearchBar data={data} setFilteredData={setFilteredData} filterKey={'name'} />
                        <div className="_1fry">
                            <Pagination page={page} setPage={setPage} count={data?.length} rowsPerPage={rowsPerPage} />
                            {/* <TableFilter data={data} setFilteredData={setFilteredData} filterKey={'student_id.first_name'} /> */}
                        </div>
                    </div>
                    <div className='studentportal'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th id='ms-1'>S.No</th>
                                    <th id='as-1'>Provider Id</th>
                                    <th id='as-1'>Provider name</th>
                                    <th id='ms-1'>Register Date</th>
                                    <th id='ms-1'>Service</th>
                                    <th id='ps'>Mobile Number</th>
                                    <th id='ps'>Years of Experience</th>
                                    <th id='hs-1'>Payments</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataToShow?.map((data, item) => (
                                    <tr key={item} >
                                        <td className='cuatros-pegs'>{startIndex + item + 1}</td>
                                        <td className='cuatros-pegs'>{data?.sp_id}</td>
                                        <td className='cuatros-pegs'>{data?.name}</td>
                                        <td className='cuatros-pegs'>{data?.createdAt.slice(0, 10)}</td>
                                        <td className='cuatros-pegs'>{data?.serviceType?.categories?.name}</td>
                                        <td className='prawners-bind-1'>{data?.mobile_no}</td>
                                        <td className='prawners-bind-1'>{data?.Experience}</td>
                                        <td className='prawners-bind-1'>
                                            <div className='outweigh-idly'>
                                                <img className='cheers-owl' src={view} alt="" onClick={() => { navigate(`/Home/Payments/PaymentDetails/${data._id}`) }} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>)}
        </div>
    )
}

export default Payments
