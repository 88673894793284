import React, { useEffect, useState } from 'react';
import view from '../assets/View.svg';
import SearchBar from '../Components/SearchBar';
import Pagination from '../Components/Pagination';
import './css/Users.css';
import TopBar from '../Components/TopBar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import instance from '../utils/api';
import Loading from '../assets/Loading.svg';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';




const Users = () => {
    const [filteredData, setFilteredData] = useState([]);
    const [filteredData1, setFilteredData1] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [open, setOpen] = useState(false); // Modal state
    const rowsPerPage = 10;
    const startindex = (page - 1) * rowsPerPage;

    const handleUsers = async () => {
        try {
            const response = await instance.get('api/Admin/getUsersList');
            console.log(response.data.UsersList);
            const filter = response.data.UsersList.filter((data) => data.swaap_id);
            setData(filter);
            setFilteredData(filter);
        } catch (error) {
            console.log(error);
            toast.error(error.data.message);
        } finally {
            setLoading(false);
        }
    }
    const handlefailusers = async()=>{
        try {
            const response = await instance.get('api/Admin/nouserRegister');
            console.log(response.data);
            const filter = response.data;
            setData1(filter);
            setFilteredData1(filter);
        } catch (error) {
            console.log(error);
            toast.error(error.data.message);
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        handleUsers();
        handlefailusers();
    }, []);

    console.log(data1);
    // console.log(filteredData);

    const dataToShow = filteredData?.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    const dataToShow1 = filteredData1?.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <TopBar title={"Users"} />
            {loading ?
                (
                    <div className="loading-spinner">
                        <img src={Loading} alt="loading" height={80} width={80} />
                    </div>
                ) :
                (<div className='_2wae'>
                    <div className="forget-hark">
                        <SearchBar data={data} setFilteredData={setFilteredData} filterKey={'name'} />
                        <div className="_1fry" style={{ display: 'flex' }}>
                            <Pagination page={page} setPage={setPage} count={data.length} rowsPerPage={rowsPerPage} />
                            <Button
                                onClick={handleClickOpen}
                                sx={{
                                    backgroundColor: '#F78E33',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#F9A65D', // Adjust this to your desired light color
                                    },
                                }}
                            >
                                Failure users
                            </Button>

                            {/* <TableFilter data={data} setFilteredData={setFilteredData} filterKey={'student_id.first_name'} /> */}
                        </div>
                    </div>
                    <div className='studentportal'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th id='as-1'>S.No</th>
                                    <th id='as-1'>Swaap Id</th>
                                    <th id='ps'>User  Name</th>
                                    <th id='ps'>Mobile Number</th>
                                    <th id='hs-1'>Location</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataToShow.map((data, item) => (
                                    <tr key={item} >
                                        <td className='cuatros-pegs'>{startindex + item + 1}</td>
                                        <td className='cuatros-pegs'>{data.swaap_id}</td>
                                        <td className='prawners-bind-1'>{data.name}</td>
                                        <td className='prawners-bind-1'>{data.mobile_no}</td>
                                        <td className='prawners-bind-1'>{data.location}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>)}
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        width: '50%',
                        maxWidth: '600px', // Optional: set a max-width to avoid being too large
                    },
                }}
            >
                <DialogTitle sx={{ display: 'flex',justifyContent:'space-between',alignItems:'center',fontWeight:600 }}>Failure Users<CloseIcon onClick={handleClose} sx={{cursor:'pointer'}} /></DialogTitle>
                <DialogContent>
                    <div className='_2wae' style={{padding:'0px',margin:'0px'}}>
                        <div className="forget-hark">
                            <SearchBar data={data1} setFilteredData={setFilteredData} filterKey={'mobile_no'} />
                            <div className="_1fry">
                                <Pagination page={page} setPage={setPage} count={data1.length} rowsPerPage={rowsPerPage} />
                            </div>
                        </div>
                        <div className='studentportal'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th id='as-1'>S.No</th>
                                        <th id='ps'>Mobile Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataToShow1.map((data, item) => (
                                        <tr key={item}>
                                            <td className='cuatros-pegs'>{startindex + item + 1}</td>
                                            <td className='prawners-bind-1'>{data.mobile_no}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </DialogContent>
                
            </Dialog>

        </div>
    )
}

export default Users
