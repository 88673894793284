import React, { useEffect, useState } from 'react';
import view from '../assets/View.svg';
import SearchBar from '../Components/SearchBar';
import Pagination from '../Components/Pagination';
import './css/Users.css';
import TopBar from '../Components/TopBar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import instance from '../utils/api';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../assets/Loading.svg';


const PaymentDetails = () => {
    const [filteredData, setFilteredData] = useState(null);
    const [page, setPage] = useState(1);
    const [data, setData] = useState(null);
    const [dialog, setDialog] = useState(false);
    const [dialogData, setDialogData] = useState();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { id } = useParams();
    const rowsPerPage = 10;
    const startIndex = (page - 1) * rowsPerPage;
    // console.log(id);

    const spList = async () => {
        try {
            const response = await instance.get(`/api/Admin/getServiceProviderPaymentDetails/${id}`);
            console.log(response);
            if (response.data.success === true) {
                setData(response.data.paymentDatails); // Set the filtered data
                setFilteredData(response.data.paymentDatails.payments); // Also set the filtered data
            }
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data.message);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        spList();
    }, [])

    console.log(data);
    console.log(filteredData);

    const dataToShow = filteredData?.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    return (
        <div>
            <TopBar title={`${data?.Service_Provider_id?.name} Payments`} button={"Back"} onClick={() => { navigate(-1) }} />
            {loading ?
                (
                    <div className="loading-spinner">
                        <img src={Loading} alt="loading" height={80} width={80} />
                    </div>
                ) :
                (<div className='_2wae'>
                    <div className="forget-hark">
                        {/* <SearchBar data={data} setFilteredData={setFilteredData} filterKey={'name'} /> */}
                        <b>Next Payment Date : <u>{data?.membership_end_date?.slice(0, 10)}</u></b>
                        <div className="_1fry">
                            <Pagination page={page} setPage={setPage} count={filteredData?.length} rowsPerPage={rowsPerPage} />
                            {/* <TableFilter data={data} setFilteredData={setFilteredData} filterKey={'student_id.first_name'} /> */}
                        </div>
                    </div>
                    <div className='studentportal'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th id='ms-1'>S.No</th>
                                    <th id='as-1'>Order Id</th>
                                    <th id='as-1'>Payment Date</th>
                                    {/* <th id='as-1'>Next Payment Date</th> */}
                                    <th id='ps'>Amount</th>
                                    <th id='ps'>MemberShip Plan</th>
                                    <th id='ps'>Payment Status</th>
                                    <th id='ps'>Receipt</th>
                                    {/* <th id='hs-1'>Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {dataToShow?.map((paymentdata, item) => (
                                    <tr key={item} >
                                        <td className='cuatros-pegs'>{startIndex + item + 1}</td>
                                        <td className='cuatros-pegs'>{paymentdata?.order_id}</td>
                                        <td className='cuatros-pegs'>{paymentdata?.payment_date?.slice(0, 10)}</td>
                                        {/* <td className='cuatros-pegs'>{data?.membership_end_date?.slice(0, 10)}</td> */}
                                        <td className='prawners-bind-1'>{paymentdata?.amount}</td>
                                        <td className='prawners-bind-1'>{paymentdata?.membership_plan}</td>
                                        <td className='prawners-bind-1'>{paymentdata?.status}</td>
                                        <td className='prawners-bind-1'>{paymentdata?.receipt}</td>
                                        {/* <td className='prawners-bind-1'>
                                        <div className='outweigh-idly'>
                                            <img className='cheers-owl' src={view} alt="" onClick={() => { setDialog(true); setDialogData(data); }} />
                                        </div>
                                    </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>)}
        </div>
    )
}

export default PaymentDetails
