import React, { useEffect, useState } from 'react';
import DashboardCard from '../Components/Dashboard_card'; // Ensure this component name matches
import TotalBookings from '../assets/total_book.svg';
import Dash2 from '../assets/Dash2.svg';
import Dash3 from '../assets/dash3.svg';
import Dash4 from '../assets/Dash4.svg';
import "./css/Dashboard.css";
import instance from '../utils/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Chart from 'react-apexcharts';
import Loading from '../assets/Loading.svg';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [series, setSeries] = useState([0, 0]); // Initialize with default values
    // console.log(series);
    const navigate = useNavigate();

    // const [options] = useState({
    //     chart: {
    //         width: 380,
    //         type: 'donut',
    //     },
    //     plotOptions: {
    //         pie: {
    //             startAngle: -90,
    //             endAngle: 270,
    //             donut: {
    //                 size: '60%', // Adjust the inner radius (e.g., 60% of the outer radius)
    //             }
    //         },
    //     },
    //     dataLabels: {
    //         enabled: false,
    //     },
    //     fill: {
    //         type: 'gradient',
    //     },
    //     colors: ['#1D71AE', '#F78E33'],
    //     labels: ['Users', 'Service Providers'], // Custom labels for hover
    //     legend: {
    //         formatter: function (val, opts) {
    //             const labels = ['Users', 'Service Providers']; // Custom labels for the legend
    //             const seriesValue = opts.w.globals.series[opts.seriesIndex];
    //             return labels[opts.seriesIndex] + " - " + (seriesValue !== undefined ? seriesValue.toString() : '0');
    //         }
    //     },
    //     responsive: [
    //         {
    //             breakpoint: 480,
    //             options: {
    //                 chart: {
    //                     width: 200,
    //                 },
    //                 legend: {
    //                     position: 'bottom',
    //                 },
    //             },
    //         },
    //     ],
    // });

    const options = {
        colors: ["#1D71AE", "#F78E33"],
        labels: ["Users", "Service Providers"],
        plotOptions: {
            pie: {
                expandOnClick: true,
                donut: {
                    size: "50px",
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            showAlways: true,
                            fontSize: '18px',
                            color: "#202224",
                            fontWeight: "700",
                            fontFamily: "Inter",
                            lineHeight: "21.78px"
                        }
                    }
                }
            }
        },
        legend: {
            show: false
        }
    };
    

    const handleCount = async () => {
        try {
            const response = await instance.get('api/Admin/getDashboardCounts');
            console.log(response.data);
            const data = response.data;
            setData(response.data); // Ensure data is an object
            setSeries([data?.userCount || 0, data?.ServiceprovidersCount || 0]);
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleCount();
    }, []);

    // useEffect(() => {
    //     if (data) {
    //         // Ensure series data is always valid numbers
    //     }
    // }, [data]);

    return (
        <>
            {loading ? (
                <div className="loading-spinner">
                    <img src={Loading} alt="loading" height={80} width={80} />
                </div>
            ) : (
                <div className='Dashboard'>
                    <div className='Dashboard_cards'>
                        <DashboardCard image={TotalBookings} title={"Total Bookings"} count={data?.bookingCount || 0} onClick={()=>{navigate('/Home/Bookings')}} />
                        <DashboardCard image={Dash2} title={"Total Revenue"} count={`₹${data?.total_revenue || 0}.00`} onClick={()=>{navigate('/Home/Payments')}}/>
                        <DashboardCard image={Dash3} title={"Total Users"} count={data?.userCount || 0} onClick={()=>{navigate('/Home/Users')}}/>
                        <DashboardCard image={Dash4} title={"Total Service Providers"} count={data?.ServiceprovidersCount || 0} onClick={()=>{navigate('/Home/Service_Provider')}}/>
                    </div>
                    <div className='Dashboard_blocks'>
                        <div className='Customer'>
                            <h1>Customer Overview</h1>
                            <p>Customer registered through SWAAP</p>
                            <div id="chart" className='dashboard_pie_chart'>
                                <Chart
                                    options={options}
                                    series={series}
                                    type="donut"
                                    width="350px"
                                    height={300}
                                />
                            </div>
                            <div id="html-dist"></div>

                        </div>
                        <div className='Notifications'>
                            <h1>Notifications</h1>
                            <p>Today's notifications</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Dashboard;
