import React, { useState } from 'react'
import TopBar from '../Components/TopBar'
import User_contect_sup from '../Components/User_contect_sup';
import SP_contect_sup from '../Components/SP_contect_sup';


const list = [
  { id: "1", name: "User" },
  { id: "2", name: "Service Provider" }
]
const Contact_support = () => {
  const [option, setOption] = useState("");
  if (option === "User") {
    return (
      <>
        <TopBar title={"Contact Support"} list={list} option={(e) => { setOption(e.target.getAttribute('name')) }} Active={"User"} />
        <User_contect_sup />
      </>
    )
  }
  if (option === "Service Provider") {
    return (
      <>
        <TopBar title={"Contact Support"} list={list} option={(e) => { setOption(e.target.getAttribute('name')) }} Active={"Service Provider"} />
        <SP_contect_sup />
      </>
    )
  }
  return (
    <div>
      <TopBar title={"Contact Support"} list={list} option={(e) => { setOption(e.target.getAttribute('name')) }} Active={"User"} />
      <User_contect_sup />
    </div>
  )
}

export default Contact_support
