import React, { useEffect, useState } from 'react'
import TopBar from '../Components/TopBar'
import Category_card from '../Components/Category_card';
import "./css/Categories.css";
import plumber from '../assets/Plumber.svg';
import { useNavigate } from 'react-router-dom';
import All_Categories from './All_Categories';
import Categ_recently from '../Components/Categ_recently';
import Categ_delete from '../Components/Categ_delete';
import Modal from 'react-modal';
import { FileUploader } from "react-drag-drop-files";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import instance from '../utils/api';
import Loading from '../assets/Loading.svg';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "40%",
        backgroundColor: '#ffffff',
        borderRadius: '6px'
    },
};
const fileTypes = ["JPG", "PNG", "GIF"];

const Categories = () => {
    const navigate = useNavigate();
    const [editdata, seteditdata] = useState(null);
    const [option, setOption] = useState("All Categories");
    const [dialog, setDialog] = useState(false);
    const [editdialog, setEditDialog] = useState(false);
    const [file, setFile] = useState(null);
    const [data, setData] = useState();
    const [categoryName, setCategoryName] = useState("");
    const [price, setprice] = useState("");
    const [editcategoryName, setEditCategoryName] = useState("");
    const [editprice, setEditprice] = useState("");
    const [url, setUrl] = useState("");
    const [isValid, setIsValid] = useState(null);
    const [isImgDisabled, setIsImgDisabled] = useState(false);
    const [isUrlDisabled, setIsUrlDisabled] = useState(false);
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(true);
    const [removeDisable, setRemoveDisable] = useState(false);
    const [disable, setDisable] = useState(false);
    // console.log(editdata);

    const fileTypes = ["JPG", "PNG", "SVG"];

    // const handleChange = (file) => {
    //     setFile(file);
    // };
    // console.log(values);

    const list = [
        { id: "1", name: "All Categories" },
        // { id: "2", name: "Recently added" },
        { id: "2", name: "Deleted" }
    ]
    const handleCategory = async () => {
        try {
            const response = await instance.get('api/Admin/CategoryList/true');
            // console.log(response.data);
            setData(response.data.CategoriesList);
            // setFilteredData(response.data.UsersList);
        } catch (error) {
            console.log(error);
            toast.error(error.data.message);
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        handleCategory();
    }, []);
    const RemoveCategory = async (data) => {
        setRemoveDisable(true);
        try {
            const response = await instance.post('/api/Admin/removeCategory', {
                categoryId: data._id,
                status: data.status ? false : true
            });
            console.log(response);
            toast.success(response.data.message);
            handleCategory();
            setRemoveDisable(false);
            setReload(true);
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message);
            setRemoveDisable(false);
        }
    }

    //add Category
    const AddCategory = async () => {
        try {
            if (!categoryName) {
                return toast.error('Please enter category name');
            }
            if (!price) {
                return toast.error('Please enter Price');
            }
            if (!file && !url) {
                return toast.error('Please select a file');
            }
            setDisable(true);
            const formData = new FormData();
            formData.append('category', file);
            formData.append('name', categoryName);
            formData.append('price', price);
            // formData.append('url', url);
            const response = await instance.post('/api/Admin/createCategory', formData);
            console.log(response);
            if (response.data.success === true) {
                toast.success(response.data.message);
                setDialog(false);
                setCategoryName('');
                setprice('');
                setFile(null);
                handleCategory();
                setDisable(false);
            } else {
                toast.error(response.data.message);
                setDisable(false);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message);
            setDisable(false);
        }
    }
    // function isValidImageUrl(url) {
    //     return new Promise((resolve) => {
    //         const img = new Image();
    //         img.onload = () => resolve(true);
    //         img.onerror = () => resolve(false);
    //         img.src = url;
    //     });
    // }
    // const handleCheckImage = async () => {
    //     const result = await isValidImageUrl(url);
    //     setIsValid(result);
    // };
    // Function to check if the URL is a valid image URL
    function isValidImageUrl(url) {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
            img.src = url;
        });
    }

    // Handle image URL validation
    const handleCheckImage = async () => {
        const result = await isValidImageUrl(url);
        setIsValid(result);
        if (result) {
            setIsImgDisabled(true); // Disable image upload if URL is valid
        } else {
            setIsImgDisabled(false); // Enable image upload if URL is invalid
        }
    };

    // Handle file change
    const handleFileChange = (file) => {
        setFile(file);
        if (file) {
            setIsUrlDisabled(true); // Disable URL input if an image is uploaded
        } else {
            setIsUrlDisabled(false); // Enable URL input if the image is removed
        }
    };

    // Handle clearing the image URL input
    const handleUrlChange = (e) => {
        const newUrl = e.target.value;
        setUrl(newUrl);
        setIsValid(null); // Reset the validation message
        if (newUrl) {
            setIsImgDisabled(true); // Disable image upload if there is URL input
        } else {
            setIsImgDisabled(false); // Enable image upload if URL input is cleared
        }
    };

    // Handle clearing the file upload
    const handleRemoveImage = () => {
        setFile(null);
        setIsUrlDisabled(false); // Enable URL input when file is removed
    };
    const HandleEdit = async () => {
        try {
            if (!editcategoryName) {
                return toast.error('Please enter category name');
            }
            if (!editprice) {
                return toast.error('Please enter Price');
            }
            // if (!file && !url) {
            //     return toast.error('Please select a file');
            // }
            setDisable(true);
            const formData = new FormData();
            formData.append('category', file);
            formData.append('name', editcategoryName);
            formData.append('price', editprice);
            formData.append('categoryId', editdata?._id);
            const response = await instance.post('/api/Admin/editCategory', formData);
            console.log(response);
            if (response.data.success === true) {
                toast.success(response.data.message);
                setEditDialog(false);
                setEditCategoryName('');
                setEditprice('');
                setFile(null);
                handleCategory();
                setDisable(false);
            } else {
                toast.error(response.data.message);
                setDisable(false);
            }
        } catch (error) {
            console.error(error);
            toast.error(error.response.data.message);
            setDisable(false);
        }
    }
    const handleEditCategory = (data) => {
        // console.log(data);
        setEditCategoryName(data?.name)
        setEditprice(data?.price)
        seteditdata(data); // Update the edit data first
        setEditDialog(true); // Then, open the dialog
    };
    return (
        <>
            {loading ? (
                <div className="loading-spinner">
                    <img src={Loading} alt="loading" height={80} width={80} />
                </div>
            ) : (
                <div>
                    <TopBar title={"Categories"} list={list} button={"Add Categories"} option={(e) => { setOption(e.target.getAttribute('name')) }} Active={option} onClick={() => { setDialog(true) }} />
                    {option === "All Categories" ? (
                        <div className='categories_cards'>
                            {data ? data.map((data) => (
                                <Category_card name={data.name} image={data.image} removeDisable={removeDisable} edit={() => handleEditCategory(data)} remove={() => { RemoveCategory(data); }} onClick={() => { navigate(`/Home/Categories/Service_Providers/${data._id}`) }} />
                            )) : null}
                        </div>
                    ) : null}
                    {/* {option === "Recently added" ? (
                <Categ_recently />
            ) : null} */}
                    {option === "Deleted" ? (
                        <Categ_delete RemoveCategory={RemoveCategory} reload={reload} setReload={setReload} removeDisable={removeDisable} />
                    ) : null}
                    {option === "ServiceProviders" ? (
                        <All_Categories />
                    ) : null}

                    {dialog ? <Modal
                        isOpen={dialog}
                        // onAfterOpen={afterOpenModal}
                        onRequestClose={() => { setDialog(false) }}
                        style={customStyles}
                        contentLabel="Example Modal"

                    >
                        <div>
                            <div className='Dialog_header_category'>
                                <h1>Add Category</h1>
                                <p>To add Categories to the list, fill this following information</p>
                            </div>
                            <div className='fields'>
                                <div className='single_field'>
                                    <label>Category Name<span className='label_required'>*</span></label>
                                    <input
                                        type='text'
                                        placeholder='Enter Category name'
                                        name='categoryName'
                                        value={categoryName}
                                        onChange={(e) => setCategoryName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className='single_field'>
                                    <label>Price<span className='label_required'>*</span></label>
                                    <input
                                        type='text'
                                        placeholder='Enter Price'
                                        name='Price'
                                        value={price}
                                        onChange={(e) => setprice(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='Image_block'>
                                <h3>Banner Image Upload<span className='label_required'>*</span></h3>
                                <p>Add Your Image here</p>
                                <FileUploader
                                    disabled={isImgDisabled}
                                    handleChange={handleFileChange}
                                    name="file"
                                    label="Browse or Drag your File(s)"
                                    types={fileTypes}
                                    classes='upload_button'
                                />
                                {/* {file && (
                                    <div>
                                        <p>File uploaded: {file.name}</p>
                                        <button onClick={handleRemoveImage}>Remove Image</button>
                                    </div>
                                )} */}
                                <p>Only support .jpg, .png, and .svg files</p>
                                {/* <div className='URL'>
                                    <span className='URL_or'>or</span>
                                    <h3>Upload from URL</h3>
                                    <div className='button_url'>
                                        <input
                                            type="text"
                                            placeholder="Enter URL"
                                            value={url}
                                            name='url'
                                            onChange={handleUrlChange}
                                            disabled={isUrlDisabled}
                                            className={isUrlDisabled ? 'disabled-field' : ''}
                                        />
                                        <button onClick={handleCheckImage}>Validate URL</button>
                                    </div>
                                    {isValid !== null && (
                                        <p>{isValid ? 'Valid image URL!' : 'Invalid image URL.'}</p>
                                    )}
                                </div> */}
                            </div>
                            <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end', marginRight: '42px' }}>
                                <button onClick={() => { setDialog(false) }} className='cancel'>Cancel</button>
                                <button className='ADD' onClick={AddCategory} disabled={disable} >ADD</button>
                            </div>
                        </div>
                    </Modal> : null}
                    {editdialog ? <Modal
                        isOpen={editdialog}
                        // onAfterOpen={afterOpenModal}
                        onRequestClose={() => { setEditDialog(false) }}
                        style={customStyles}
                        contentLabel="Example Modal"

                    >
                        <div>
                            <div className='Dialog_header_category'>
                                <h1>Edit Category</h1>
                                <p>To add Categories to the list, fill this following information</p>
                            </div>
                            <div className='fields'>
                                <div className='single_field'>
                                    <label>Category Name<span className='label_required'>*</span></label>
                                    <input
                                        type='text'
                                        placeholder='Enter Category name'
                                        name='categoryName'
                                        value={editcategoryName}
                                        onChange={(e) => setEditCategoryName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className='single_field'>
                                    <label>Price<span className='label_required'>*</span></label>
                                    <input
                                        type='text'
                                        placeholder='Enter Price'
                                        name='Price'
                                        value={editprice}
                                        onChange={(e) => setEditprice(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='Image_block'>
                                <h3>Banner Image Upload<span className='label_required'>*</span></h3>
                                <p>If you Want to Change Image then only Upload.</p>
                                <FileUploader
                                    disabled={isImgDisabled}
                                    handleChange={handleFileChange}
                                    name="file"
                                    label="Browse or Drag your File(s)"
                                    types={fileTypes}
                                    classes='upload_button'
                                />
                                {/* {file && (
                                    <div>
                                        <p>File uploaded: {file.name}</p>
                                        <button onClick={handleRemoveImage}>Remove Image</button>
                                    </div>
                                )} */}
                                <p>Only support .jpg, .png, and .svg files</p>
                                {/* <div className='URL'>
                                    <span className='URL_or'>or</span>
                                    <h3>Upload from URL</h3>
                                    <div className='button_url'>
                                        <input
                                            type="text"
                                            placeholder="Enter URL"
                                            value={url}
                                            name='url'
                                            onChange={handleUrlChange}
                                            disabled={isUrlDisabled}
                                            className={isUrlDisabled ? 'disabled-field' : ''}
                                        />
                                        <button onClick={handleCheckImage}>Validate URL</button>
                                    </div>
                                    {isValid !== null && (
                                        <p>{isValid ? 'Valid image URL!' : 'Invalid image URL.'}</p>
                                    )}
                                </div> */}
                            </div>
                            <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end', marginRight: '42px' }}>
                                <button onClick={() => { setEditDialog(false) }} className='cancel'>Cancel</button>
                                <button className='ADD' onClick={HandleEdit} disabled={disable} >Update</button>
                            </div>
                        </div>
                    </Modal> : null}
                </div>
            )}
        </>
    )
}

export default Categories
