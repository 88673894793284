import React from 'react';
import './css/TopBar.css';

const TopBar = ({ title, list, button, Pagination, option, Active,onClick }) => {
    console.log(Active);
    return (
        <div className='TopBar'>
            <div className='header'><h1>{title}</h1><h1> {Pagination ? " >" : ""}{Pagination ? Pagination : ""}</h1></div>
            <ul>
                {list?.map(item => (
                    <li
                        key={item.id}
                        name={item.name}
                        onClick={option}
                        style={{
                            borderBottom: Active === item.name ? '2px solid rgba(29, 113, 174, 1)' : 'none',
                            color: Active === item.name ? 'rgba(29, 113, 174, 1)' : 'rgba(95, 109, 126, 1)',
                            paddingBottom: Active === item.name ? '37px' : 'none'
                        }}
                    >
                        {item.name}
                    </li>
                ))}
            </ul>
            {button ?
                <button onClick={onClick}>{button}</button> : ""}
        </div>
    )
}

export default TopBar
