import axios from 'axios';

const Token = localStorage.getItem('token');

// const api = 'http://localhost:4040/';
// const api = 'https://swaap-d45nq.ondigitalocean.app/';
const api = 'https://backend.theswaap.com/';
// const api = 'http://13.202.116.108/';

const createAxiosInstance = (token) => {
    return axios.create({
        baseURL: `${api}/`,
        headers: {
            Accept: 'aaplication/json',
            Authorization: `Bearer ${token}`,
            'x-token': token
        },
    });
}

export const instance = createAxiosInstance(Token);

export const updateToken = (newToken) =>{
    instance.defaults.headers['Authorization'] = `Bearer ${newToken.trim()}`;
    instance.defaults.headers['x-token'] = newToken
};

export default instance;