import logo from './logo.svg';
import './App.css';
import Login from './Pages/Login';
import LoginComp from './Components/LoginComp';
import Routes from './Routes/routes';

function App() {
  return (
    <>
      <Routes />
      {/* <LoginComp /> */}
    </>
  );
}

export default App;
