import React, { useState } from 'react';
import LoginComp from '../Components/LoginComp';
import './css/Login.css';
import swaapLogo from '../assets/swaap_logo.svg';
import emailIcon from '../assets/email_icon.svg';
import eyeClose from '../assets/eye.svg';
import eyeOpen from '../assets/eye.svg'; // New icon for showing password
import { useNavigate } from "react-router-dom";
import instance, { updateToken } from '../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = ({ setTriggerRefresh }) => {
    const [data, setData] = useState({
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });

        // Remove error message for the field when the user types
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }

    const validate = () => {
        let tempErrors = {};
        let isValid = true;

        if (!data.email) {
            tempErrors.email = "Email is required";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            tempErrors.email = "Email is invalid";
            isValid = false;
        }

        if (!data.password) {
            tempErrors.password = "Password is required";
            isValid = false;
        } else if (data.password.length < 6) {
            tempErrors.password = "Password must be at least 6 characters long";
            isValid = false;
        }

        setErrors(tempErrors);
        return isValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            setIsSubmitting(true); 
            try {
                const response = await instance.post('/api/Admin/Login', data);
                if (response?.data?.success) {
                    const token = response?.data?.token;
                    localStorage.setItem('token', token);
                    updateToken(token);
                    setTriggerRefresh(true);
                    navigate('/Home/Dashboard');
                    toast.success(response?.data.message);
                } else {
                    toast.error(response?.data.message);
                }
            } catch (error) {
                console.error(error);
                toast.error(error?.response?.data?.message || 'An error occurred');
            } finally {
                setIsSubmitting(false); 
            }
        }
    }

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='login_page'>
            <LoginComp />
            <div className='login_page_details'>
                <div className='Login_details'>
                    <div className='Login_header'>
                        <h1>Welcome Back to SWAAP!!</h1>
                        <h3>We are happy to have you back</h3>
                    </div>
                    <img src={swaapLogo} alt='swaap Logo' />
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='Email_field'>
                        <label>Enter your email or phone number</label>
                        <div className='input_field'>
                            <img src={emailIcon} alt='email icon' />
                            <input
                                type='email'
                                name='email'
                                placeholder='Enter your email or phone number'
                                onChange={handleChange}
                                value={data.email}
                            />
                        </div>
                        {errors.email && <span className='error'>{errors.email}</span>}
                    </div>
                    <div className='password_field'>
                        <label>Enter the Password</label>
                        <div className='input_field'>
                            <input
                                type={showPassword ? 'text' : 'password'} // Toggle between text and password
                                name='password'
                                placeholder='**********'
                                onChange={handleChange}
                                value={data.password}
                            />
                            <img 
                                src={showPassword ? eyeOpen : eyeClose} // Switch icon based on visibility state
                                alt='toggle password visibility' 
                                onClick={togglePasswordVisibility}
                                style={{ cursor: 'pointer' }} // Add cursor pointer to indicate clickable
                            />
                        </div>
                        {errors.password && <span className='error'>{errors.password}</span>}
                    </div>
                    <div className='Forgot_password'>
                        {/* <div style={{ display: "flex" }}>
                            <input type='checkbox' name='Rememberme' />
                            <p>Remember me</p>
                        </div> */}
                        {/* <a onClick={() => navigate("/forgot_Password")}>Forgot Password?</a> */}
                    </div>
                    <div className='submit_button'>
                        <button type='submit' disabled={isSubmitting}>
                            {isSubmitting ? 'Logging in...' : 'Login'}
                        </button>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Login;
