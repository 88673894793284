import React from 'react';
import Topnav from './Topnav';
import Sidebar from './Sidebar';
import "./css/Home.css";
import { Outlet } from 'react-router-dom';

const Home = () => {
    return (
        <div className='Home'>
            <Sidebar />
            <Topnav />
            <div style={{ position: 'absolute', top: '110px', left: '20%', width: '78%' }}>
                <Outlet /></div>
        </div>
    )
}

export default Home
