import React, { useEffect, useState } from 'react'
import delete_cat from '../assets/Delete_catg.svg';
import './css/Categ_delete.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Category_card from './Category_card';
import { useNavigate } from 'react-router-dom';
import instance from '../utils/api';
const Categ_delete = ({ RemoveCategory, reload, setReload, removeDisable }) => {
    const [data, setData] = useState();
    const navigate = useNavigate();
    console.log(removeDisable);

    const handleCategory = async () => {
        try {
            const response = await instance.get('api/Admin/CategoryList/false');
            // console.log(response.data);
            setData(response.data.CategoriesList);
            // setFilteredData(response.data.UsersList);
        } catch (error) {
            console.log(error);
            toast.error(error.data.message);
        }
    }
    useEffect(() => {
        handleCategory();
    }, []);
    useEffect(() => {
        if (reload === true) {
            handleCategory();
            setReload(false);
        }
    }, [reload]);
    console.log(reload);

    return (
        <>
            <div className='categories_cards'>
                {data ? data.map((data) => (
                    <Category_card name={data.name} image={data.image} remove={() => { RemoveCategory(data); }} removeDisable={removeDisable} removename={"undo"} onClick={() => { navigate(`/Home/Categories/Service_Providers/${data._id}`) }} />
                )) : (
                    <div className='Category_Delete'>
                        <img src={delete_cat} />
                    </div>
                )}
            </div>
        </>
    )
}

export default Categ_delete
