import React, { useEffect, useState } from 'react';
import view from '../assets/View.svg';
import SearchBar from '../Components/SearchBar';
import Pagination from '../Components/Pagination';
import './css/All_Categories.css';
import TopBar from '../Components/TopBar';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import instance from '../utils/api';
import nosp from '../assets/noSP.svg';
import Loading from '../assets/Loading.svg';


// const data = [
//     { Provider_name: "Satya", Register_date: "2024-07-31", Location: "KBHP,Hyderabad", MobileNo: "7569834357", YearsOfExp: "2 Yrs" },
//     { Provider_name: "Satyach", Register_date: "2024-07-31", Location: "KBHP,Hyderabad", MobileNo: "7569834357", YearsOfExp: "2 Yrs" },
//     { Provider_name: "Anjani", Register_date: "2024-07-31", Location: "KBHP,Hyderabad", MobileNo: "7569834357", YearsOfExp: "2 Yrs" },
// ]
// const list = [
//     { id: "1", name: "All Categories" },
//     { id: "2", name: "Recently added" },
//     { id: "3", name: "Deleted" }
// ]

const All_Categories = () => {
    const [page, setPage] = useState(1);
    const { id } = useParams();
    const [data, setData] = useState();
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);


    const HandleServiceProviders = async () => {
        try {
            const response = await instance.get(`/api/Admin/getServiceProvider/${id}`);
            console.log(response);
            setData(response.data.ServiceProviderList);
            setFilteredData(response.data.ServiceProviderList);
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message);
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        HandleServiceProviders();
    }, [])
    console.log(data);


    const rowsPerPage = 10;
    const dataToShow = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    return (
        <>
            {loading ? (
                <div className="loading-spinner">
                    <img src={Loading} alt="loading" height={80} width={80} />
                </div>
            ) : (

                <>
                    {!data?.length ? (
                        <div className='Empty'>
                            <img src={nosp} />
                        </div>
                    ) :
                        (<div>
                            <div className='_2wae'>
                                <div className="forget-hark">
                                    <SearchBar data={data} setFilteredData={setFilteredData} filterKey={'Provider_name'} />
                                    <div className="_1fry">
                                        <Pagination page={page} setPage={setPage} count={data?.length} rowsPerPage={rowsPerPage} />
                                        {/* <TableFilter data={data} setFilteredData={setFilteredData} filterKey={'student_id.first_name'} /> */}
                                    </div>
                                </div>
                                <div className='studentportal'>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th id='as-1'>Provider Id</th>
                                                <th id='as-1'>Provider name</th>
                                                <th id='ms-1'>Title</th>
                                                {/* <th id='ms-1'>Location</th> */}
                                                <th id='ps'>Mobile Number</th>
                                                <th id='ps'>Years of Experience</th>
                                                <th id='hs-1'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataToShow.map((data, item) => (
                                                <tr key={item} >
                                                    <td className='cuatros-pegs'>{data.sp_id}</td>
                                                    <td className='cuatros-pegs'>{data.name}</td>
                                                    <td className='cuatros-pegs'>{data.serviceType.title}</td>
                                                    {/* <td className='cuatros-pegs'>{data.Location}</td> */}
                                                    <td className='prawners-bind-1'>{data.mobile_no}</td>
                                                    <td className='prawners-bind-1'>{data.Experience}</td>
                                                    <td className='prawners-bind-1'>
                                                        <div className='outweigh-idly'>
                                                            <img className='cheers-owl' src={view} alt="" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>)}
                </>
            )}
        </>
    )
}

export default All_Categories
