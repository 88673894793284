import React, { useState } from 'react'
import LoginComp from '../Components/LoginComp';
import './css/forgot_password.css';
import swaap_logo from '../assets/swaap_logo.svg';
import email_icon from '../assets/email_icon.svg';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import eye_close from '../assets/eye.svg';


const Forgot_password = () => {
    const [otp, setOtp] = useState('');

    const navigate = useNavigate();
    return (
        <div className='forgot_page'>
            <LoginComp />
            <div className='login_page_details' >
                <div className='Login_details'>
                    <div className='Login_header'>
                        <h1>Enter your email or phone number</h1>
                        <h3>Please enter your email or phone number</h3>
                    </div>
                    <img src={swaap_logo} alt='swaap Logo' />
                </div>
                <form onSubmit={() => { navigate("/Otp_verification") }}>
                    <div className='Email_field'>
                        <label>Enter your email or phone number</label>
                        <div className='input_field'>
                            <img src={email_icon} alt='email icon' />
                            <input type='email' name='email' placeholder='Enter your email or phone number' required />
                        </div>
                    </div>
                    <div className='otp_field'>
                        <label>Enter OTP verification code</label>
                        <OtpInput
                            className="otp-container"
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            // renderSeparator={<span> </span>}
                            renderInput={(props) => <input {...props} type='number' className='otp' />}
                        />
                    </div>
                    <div className='password_field'>
                        <label>Enter New Password</label>
                        <div className='input_field'>
                            <input type='password' name='Password' placeholder='**********' required />
                            <img src={eye_close} alt='email icon' />
                        </div>
                    </div>
                    <div className='password_field'>
                        <label>Confirm Password</label>
                        <div className='input_field'>
                            <input type='password' name='Password' placeholder='**********' required />
                            <img src={eye_close} alt='email icon' />
                        </div>
                    </div>
                    <div className='submit_button'>
                        <button type='submit'>submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Forgot_password
