import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import LoginComp from '../Components/LoginComp';
import swaap_logo from '../assets/swaap_logo.svg';
import './css/otp_verification.css';
import { useNavigate } from 'react-router-dom';

const Otp_verification = () => {
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();

    console.log(otp);
    return (
        <div className='otp_page'>
            <LoginComp />
            <div className='login_page_details' >
                <div className='Login_details'>
                    <div className='Login_header'>
                        <h1>Enter OTP Verification code</h1>
                        <h3>Please enter your 6 digit verification code</h3>
                    </div>
                    <img src={swaap_logo} alt='swaap Logo' />
                </div>
                <form onSubmit={()=>{navigate('/New_password')}}>
                    <div className='otp_field'>
                        <label>Enter OTP verification code</label>
                        <OtpInput
                            className="otp-container"
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            // renderSeparator={<span> </span>}
                            renderInput={(props) => <input {...props} type='number' className='otp' />}
                        />
                    </div>
                    <div className='submit_button'>
                        <button type='submit'>OTP Verification</button>
                    </div>
                    <p>Resend 00:00ss</p>
                </form>
            </div>
        </div>
    )
}

export default Otp_verification
