import React, { useEffect, useState } from 'react';
import view from '../assets/View.svg';
import SearchBar from '../Components/SearchBar';
import Pagination from '../Components/Pagination';
import './css/SP_new_request.css';
import TopBar from '../Components/TopBar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import instance from '../utils/api';
import reject from '../assets/rejected.svg';


const data = [
    { Provider_name: "Satya", Register_date: "2024-07-31", Service: "Carpenter", Location: "KBHP,Hyderabad", MobileNo: "7569834357", YearsOfExp: "2 Yrs" },
    { Provider_name: "Satyach", Register_date: "2024-07-31", Service: "plumber", Location: "KBHP,Hyderabad", MobileNo: "7569834357", YearsOfExp: "2 Yrs" },
    { Provider_name: "Anjani", Register_date: "2024-07-31", Service: "Carpenter", Location: "KBHP,Hyderabad", MobileNo: "7569834357", YearsOfExp: "2 Yrs" },
    { Provider_name: "Anjani", Register_date: "2024-07-31", Service: "plumber", Location: "KBHP,Hyderabad", MobileNo: "7569834357", YearsOfExp: "2 Yrs" },
    { Provider_name: "Pavani", Register_date: "2024-07-31", Service: "Carpenter", Location: "KBHP,Hyderabad", MobileNo: "7569834357", YearsOfExp: "2 Yrs" },
    { Provider_name: "Akhil", Register_date: "2024-07-31", Service: "plumber", Location: "KBHP,Hyderabad", MobileNo: "7569834357", YearsOfExp: "2 Yrs" },
]
// const list = [
//     { id: "1", name: "All Categories" },
//     { id: "2", name: "Recently added" },
//     { id: "3", name: "Deleted" }
// ]

const SP_Rejected = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [page, setPage] = useState(1);


    const handelespdata = async () => {
        try {
            const response = await instance.post(`/api/Admin/getSPByStatus/Rejected`);
            console.log(response);
            setData(response.data.sp);
            setFilteredData(response.data.sp);
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message);
        }
    }
    useEffect(() => {
        handelespdata();
    }, [])
    console.log(data);

    const rowsPerPage = 10;
    const dataToShow = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    return (
        <>
            {!data?.length ? (
                <div className='Empty'>
                    <img src={reject} />
                </div>
            ) :
                (<div>
                    {/* <TopBar title={"Categories"} list={list} button={"Add Categories"} Pagination={"Plumber"} /> */}
                    <div className='_2wae'>
                        <div className="forget-hark">
                            <SearchBar data={data} setFilteredData={setFilteredData} filterKey={'name'} />
                            <div className="_1fry">
                                <Pagination page={page} setPage={setPage} count={data.length} rowsPerPage={rowsPerPage} />
                                {/* <TableFilter data={data} setFilteredData={setFilteredData} filterKey={'student_id.first_name'} /> */}
                            </div>
                        </div>
                        <div className='studentportal'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th id='as-1'>Provider Id</th>
                                        <th id='as-1'>Provider name</th>
                                        <th id='ms-1'>Register Date</th>
                                        <th id='ms-1'>Service</th>
                                        <th id='ps'>Mobile Number</th>
                                        <th id='ps'>Years of Experience</th>
                                        {/* <th id='hs-1'>Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataToShow?.map((data, item) => (
                                        <tr key={item} >
                                            <td className='cuatros-pegs'>{data?.sp_id}</td>
                                            <td className='cuatros-pegs'>{data?.name}</td>
                                            <td className='cuatros-pegs'>{data?.createdAt.slice(0, 10)}</td>
                                            <td className='cuatros-pegs'>{data?.serviceType?.categories?.name}</td>
                                            <td className='prawners-bind-1'>{data?.mobile_no}</td>
                                            <td className='prawners-bind-1'>{data?.Experience}</td>
                                            {/* <td className='prawners-bind-1'>
                                            <div className='outweigh-idly'>
                                                <img className='cheers-owl' src={view} alt="" onClick={() => { setDialog(true); setDialogData(data); }} />
                                            </div>
                                        </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>)}
        </>
    )
}

export default SP_Rejected
