import React, { useEffect, useState } from 'react';
import view from '../assets/View.svg';
import SearchBar from '../Components/SearchBar';
import Pagination from '../Components/Pagination';
import './css/SP_new_request.css';
import TopBar from '../Components/TopBar';
import New_req from '../assets/New_request_empty.svg';
import { toast } from 'react-toastify';
import instance from '../utils/api';
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import spprofile from '../assets/spprofile.svg';
import sampleimg from '../assets/sampleimg.svg';



// const data = [
//     // { Provider_name: "Satya", Register_date: "2024-07-31", Location: "KBHP,Hyderabad", MobileNo: "7569834357", YearsOfExp: "2 Yrs" },
//     // { Provider_name: "Satyach", Register_date: "2024-07-31", Location: "KBHP,Hyderabad", MobileNo: "7569834357", YearsOfExp: "2 Yrs" },
//     // { Provider_name: "Anjani", Register_date: "2024-07-31", Location: "KBHP,Hyderabad", MobileNo: "7569834357", YearsOfExp: "2 Yrs" },
//     // { Provider_name: "Anjani", Register_date: "2024-07-31", Location: "KBHP,Hyderabad", MobileNo: "7569834357", YearsOfExp: "2 Yrs" },
//     // { Provider_name: "chalamala", Register_date: "2024-07-31", Location: "KBHP,Hyderabad", MobileNo: "7569834357", YearsOfExp: "2 Yrs" },
// ]
// const list = [
//     { id: "1", name: "All Categories" },
//     { id: "2", name: "Recently added" },
//     { id: "3", name: "Deleted" }
// ]

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "40%",
        backgroundColor: '#ffffff',
        borderRadius: '6px'
    },
};

const SP_new_request = () => {
    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [dialog, setDialog] = useState(false);
    const [dialogData, setDialogData] = useState();
    const [disable, setDisable] = useState(false);


    console.log(dialogData);

    const spList = async () => {
        try {
            const response = await instance.get('/api/Admin/serviceProviders');
            console.log(response);
            if (response.data.success === true) {


                // Corrected filter logic
                const filter = response.data.sp.filter((data) => data.sp_id); // Assuming you want to filter items with a truthy 'sp' field

                console.log("filter", filter);
                setData(filter); // Set the filtered data
                setFilteredData(filter); // Also set the filtered data
            }
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data.message);
        }
    };
    useEffect(() => {
        spList();
    }, [])
    // console.log(data);
    const handlestatus = async ({ dialogData, status }) => {
        try {
            const spid = dialogData?._id;
            // console.log(dialogData);
            // console.log(status);
            setDisable(true);
            const response = await instance.post('/api/Admin/updateSPApproalStatus', {
                sp_id: spid,
                status
            });
            if (response?.data.success === true) {
                toast.success(response.data.message);
                setDialog();
                spList();
                setDisable(false);
            } else {
                toast.error(response.data.message);
                setDisable(false);
            }
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data.message);
            setDisable(false);
        }
    }


    const rowsPerPage = 10;
    const dataToShow = filteredData?.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    return (
        <div>
            {!data?.length ? (
                <div className='Empty'>
                    <img src={New_req} />
                </div>
            ) :
                (<div className='_2wae'>
                    <div className="forget-hark">
                        <SearchBar data={data} setFilteredData={setFilteredData} filterKey={'name'} />
                        <div className="_1fry">
                            <Pagination page={page} setPage={setPage} count={data?.length} rowsPerPage={rowsPerPage} />
                            {/* <TableFilter data={data} setFilteredData={setFilteredData} filterKey={'student_id.first_name'} /> */}
                        </div>
                    </div>
                    <div className='studentportal'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th id='as-1'>Provider Id</th>
                                    <th id='as-1'>Provider name</th>
                                    <th id='ms-1'>Register Date</th>
                                    <th id='ms-1'>Service</th>
                                    <th id='ps'>Mobile Number</th>
                                    <th id='ps'>Years of Experience</th>
                                    <th id='ps'>Location</th>
                                    <th id='hs-1'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataToShow?.map((data, item) => (
                                    <tr key={item} >
                                        <td className='cuatros-pegs'>{data?.sp_id}</td>
                                        <td className='cuatros-pegs'>{data?.name}</td>
                                        <td className='cuatros-pegs'>{data?.createdAt.slice(0, 10)}</td>
                                        <td className='cuatros-pegs'>{data?.serviceType?.categories?.name}</td>
                                        <td className='prawners-bind-1'>{data?.mobile_no}</td>
                                        <td className='prawners-bind-1'>{data?.Experience}</td>
                                        <td className='prawners-bind-1'>{data?.City}</td>
                                        <td className='prawners-bind-1'>
                                            <div className='outweigh-idly'>
                                                <img className='cheers-owl' src={view} alt="" onClick={() => { setDialog(true); setDialogData(data); }} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {dialog && (
                        <Modal
                            isOpen={dialog}
                            onRequestClose={() => { setDialog(false) }}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <div className='Dialog_header'>
                                <h1>Plumber Account</h1>
                                <p>Recently new Request</p>
                            </div>
                            <div className='dialog_body' >
                                <div className='main_box'>
                                    <div className='dialog_mainbox'>
                                        <p className='dialog_title'>{dialogData?.serviceType?.categories?.name}</p>
                                        <div className='dialog_contant'>
                                            {/* <p>Provider Id: {dialogData?.sp_id}</p> */}
                                            <p><span>Type:</span> <br />{dialogData?.serviceType?.title}</p>
                                            <p><span>Yrs.of Exp:</span> <br />{dialogData?.Experience}</p>
                                            <p><span>Price/day:</span> <br />{dialogData?.serviceType?.categories?.price}</p>

                                        </div>
                                        <div className='dialog_contant_2' >
                                            <p><span>Id:</span> {dialogData?.sp_id}</p>
                                            <p><span>Description:</span> {dialogData?.description}</p>
                                        </div>
                                    </div>
                                    <div className='profile'>
                                        <img src={dialogData?.profile_pic ? dialogData?.profile_pic : spprofile} alt='sp Profile' width={"100px"} height={"100px"} />
                                        <p><span><b>Name:</b></span> {dialogData.name}</p>
                                        <p><span><b>Mobile No:</b></span> {dialogData.mobile_no}</p>
                                        <p><span><b>Aadhaar:</b></span> {dialogData.aadhaarNo}</p>
                                    </div>
                                </div>
                                <div className='dialog_down'>
                                    <p className='work_title'>Work Profiles</p>
                                    <div>
                                        <img src={sampleimg} alt='sp Profile' width={"100px"} height={"100px"} />
                                        <img src={sampleimg} alt='sp Profile' width={"100px"} height={"100px"} />
                                        <img src={sampleimg} alt='sp Profile' width={"100px"} height={"100px"} />
                                        <img src={sampleimg} alt='sp Profile' width={"100px"} height={"100px"} />
                                        <img src={sampleimg} alt='sp Profile' width={"100px"} height={"100px"} />

                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end', marginRight: '42px' }}>
                                <button onClick={() => { handlestatus({ dialogData, status: "Rejected" }) }} className='reject' disabled={disable}>Reject</button>
                                <button className='ADD' onClick={() => { handlestatus({ dialogData, status: "Approved" }) }} disabled={disable}  >Approve</button>
                            </div>

                        </Modal>
                    )}
                </div>
                )}
        </div>
    )
}

export default SP_new_request
