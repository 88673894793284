import React from 'react';
import Total_bookings from '../assets/total_book.svg';
import "./css/dashboard_card.css";

const Dashboard_card = ({ image, title, count, onClick }) => {
    return (
        <div className='Card' onClick={onClick}>
            <img src={image} alt="image" />
            <h1>{title}</h1>
            <p>{count}</p>
        </div>
    )
}

export default Dashboard_card
