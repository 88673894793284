import React from 'react'
import './css/Adds_Card.css';
import AddsImg from '../assets/Adds_img.svg';

const Adds_Card = ({ onClick, name, remove,image,Handleremove,disable }) => {
  return (
    <div className='Adds_card'>
      <img src={image} alt='category Image' />
      <div className='buttons'>
        <button className='Remove' onClick={Handleremove} disabled={disable}>{remove ? remove : "Remove"}</button>
        {/* {remove ? null : <button className='name' onClick={onClick}>{name}</button>} */}
      </div>
    </div>
  )
}

export default Adds_Card
