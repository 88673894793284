import React, { useState } from 'react';
import view from '../assets/View.svg';
import SearchBar from '../Components/SearchBar';
import Pagination from '../Components/Pagination';
import './css/SP_contect_sup.css';
import TopBar from '../Components/TopBar';
import New_req from '../assets/New_request_empty.svg';

const data = [
    { Provider_name: "Satya", Service:"Carpenter",Reason:"i am Not able to book", Location: "KBHP,Hyderabad", MobileNo: "7569834357", Action: "Received Call" },
    { Provider_name: "Satyach", Service:"Carpenter",Reason:"i am Not able to book", Location: "KBHP,Hyderabad", MobileNo: "7569834357", Action: "Received Call" },
    { Provider_name: "Anjani", Service:"Carpenter",Reason:"i am Not able to book", Location: "KBHP,Hyderabad", MobileNo: "7569834357", Action: "Call Back" },
    { Provider_name: "Anjani", Service:"Carpenter",Reason:"i am Not able to book", Location: "KBHP,Hyderabad", MobileNo: "7569834357", Action: "Received Call" },
    { Provider_name: "chalamala", Service:"Carpenter",Reason:"i am Not able to book", Location: "KBHP,Hyderabad", MobileNo: "7569834357", Action: "Call Back" },
]
// const list = [
//     { id: "1", name: "All Categories" },
//     { id: "2", name: "Recently added" },
//     { id: "3", name: "Deleted" }
// ]

const SP_contect_sup = () => {
    const [filteredData, setFilteredData] = useState(data);
    const [page, setPage] = useState(1);



    const rowsPerPage = 10;
    const dataToShow = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    return (
        <div>
            {!data.length ? (
                <div className='Empty'>
                    <img src={New_req} />
                </div>
            ) :
                (<div className='_2wae'>
                    <div className="forget-hark">
                        <SearchBar data={data} setFilteredData={setFilteredData} filterKey={'Provider_name'} />
                        <div className="_1fry">
                            <Pagination page={page} setPage={setPage} count={data.length} rowsPerPage={rowsPerPage} />
                            {/* <TableFilter data={data} setFilteredData={setFilteredData} filterKey={'student_id.first_name'} /> */}
                        </div>
                    </div>
                    <div className='studentportal'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th id='as-1'>Provider name</th>
                                    <th id='ms-1'>Service</th>
                                    <th id='ms-1'>Reason</th>
                                    <th id='ms-1'>Location</th>
                                    <th id='ps'>Mobile Number</th>
                                    <th id='hs-1'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataToShow.map((data, item) => (
                                    <tr key={item} >
                                        <td className='cuatros-pegs'>{data.Provider_name}</td>
                                        <td className='cuatros-pegs'>{data.Service}</td>
                                        <td className='cuatros-pegs'>{data.Reason}</td>
                                        <td className='cuatros-pegs'>{data.Location}</td>
                                        <td className='prawners-bind-1'>{data.MobileNo}</td>
                                        <td className='prawners-bind-1'>
                                            {data.Action}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>)}
        </div>
    )
}

export default SP_contect_sup
