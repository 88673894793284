import React, { useState } from 'react';
import './css/Service_Provider.css';
import TopBar from '../Components/TopBar';
import SP_new_request from './SP_new_request';
import SP_Approved from './SP_Approved';
import SP_Rejected from './SP_Rejected';
import Payment_Status from './Payment_Status';

const list = [
    { id: "1", name: "New Request" },
    { id: "2", name: "Approved" },
    // { id: "3", name: "Payment Status" },
    { id: "3", name: "Rejected" }
]
const Service_Provider = () => {
    const [option, setOption] = useState("New Request");
    console.log(option);

    return (
        <div>
            <TopBar title={"Service Providers"} list={list} option={(e) => { setOption(e.target.getAttribute('name')) }} Active={option} />
            {option === "New Request" ? (
                <div>
                    <SP_new_request />
                </div>
            ) : null}
            {option === "Approved" ? (
                <div>
                    <SP_Approved />
                </div>
            ) : null}
            {/* {option === "Payment Status" ? (
                <div>
                    <Payment_Status />
                </div>
            ) : null} */}
            {option === "Rejected" ? (
                <div>
                    <SP_Rejected />
                </div>
            ) : null}
        </div>
    )
}

export default Service_Provider
