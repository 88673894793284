import React from 'react';
import './css/Category_card.css';
import plumber from '../assets/Plumber.svg';

const Category_card = ({ name, image, onClick, remove, removename,edit,removeDisable }) => {
    console.log(removeDisable);
    
    return (
        <div className='Category_card'>
            <img src={image} alt='category Image' />
            <div className='buttons'>
                <button className='name' onClick={onClick}>{name}</button>
                <div className='actions'>
                    <button className='Remove' onClick={remove} disabled={removeDisable}>{removename ? removename : "Remove"}</button>
                    {edit ? <button className='Edit' onClick={edit}>Update</button>:null}
                </div>
            </div>
        </div>
    )
}

export default Category_card
