import search from '../assets/Search.svg';
import './css/Search.css';

export default function SearchBar({ data, filterKey, setFilteredData }) {

    const getNestedValue = (obj, key) => {
        return key.split('.').reduce((acc, part) => acc && acc[part], obj);
    };

    function changeHandler (e){
        setFilteredData(data.filter(item => getNestedValue(item,filterKey).toLowerCase().includes(e.target.value.toLowerCase())))
    }
    return(
        <div className="_4tet">
            <img src={search} alt="search" className="sappier-seas" />
            <input type="text" placeholder="Search" className="refold-pat" onChange={changeHandler}/>
        </div>
    )
}