import React from 'react';
import { ReactComponent as SwaapLogo } from "../assets/swaap_main_logo.svg";
import './css/Sidebar.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as DashboardIcon } from "../assets/dashboard.svg";
import { ReactComponent as ServiceP } from "../assets/serviceProvider.svg";
import { ReactComponent as Users } from "../assets/users.svg";
import { ReactComponent as Category } from "../assets/category.svg";
import { ReactComponent as Bookings } from "../assets/bookings.svg";
import Exit from '../assets/exit.svg';

const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isActive = (path) => location.pathname.startsWith(path);
    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate('/');
        window.location.reload();
      }
    return (
        <div className='swaap_logo'>
            <div className='main_header'>
                <SwaapLogo alt="Swaap Logo" />
                <div className='header_name'>
                    <h1>SWAAP</h1>
                    <p>Admin Dashboard</p>
                </div>
            </div>
            <div className='sidebar_menu'>
                <Link to="/Home/Dashboard" style={{ textDecoration: "none" }}>
                    <div className={`menu_button ${isActive("/Home/Dashboard") ? "activeRoute" : ""}`}>
                        <DashboardIcon className={`${isActive("/Home/Dashboard") ? "activeIcon" : ""}`} />
                        Dashboard
                    </div>
                </Link>
                <Link to="/Home/Categories" style={{ textDecoration: "none" }}>
                    <div className={`menu_button ${isActive("/Home/Categories") ? "activeRoute" : ""}`}>
                        <Category className={`${isActive("/Home/Categories") ? "activePath" : ""}`} />
                        Categories
                    </div>
                </Link>
                <Link to="/Home/Service_Provider" style={{ textDecoration: "none" }}>
                    <div className={`menu_button ${isActive("/Home/Service_Provider") ? "activeRoute" : ""}`}>
                        <ServiceP className={`${isActive("/Home/Service_Provider") ? "activePath" : ""}`} />
                        Service Providers
                    </div>
                </Link>
                <Link to="/Home/Users" style={{ textDecoration: "none" }}>
                    <div className={`menu_button ${isActive("/Home/Users") ? "activeRoute" : ""}`}>
                        <Users className={`${isActive("/Home/Users") ? "activePath" : ""}`} />
                        Users
                    </div>
                </Link>
                <Link to="/Home/Bookings" style={{ textDecoration: "none" }}>
                    <div className={`menu_button ${isActive("/Home/Bookings") ? "activeRoute" : ""}`}>
                        <Bookings className={`${isActive("/Home/Bookings") ? "activeIcon" : ""}`} />
                        Bookings
                    </div>
                </Link>
                <Link to="/Home/Payments" style={{ textDecoration: "none" }}>
                    <div className={`menu_button ${isActive("/Home/Payments") ? "activeRoute" : ""}`}>
                        <Bookings className={`${isActive("/Home/Payments") ? "activeIcon" : ""}`} />
                        Payments
                    </div>
                </Link>
                <Link to="/Home/Adds" style={{ textDecoration: "none" }}>
                    <div className={`menu_button ${isActive("/Home/Adds") ? "activeRoute" : ""}`}>
                        <Bookings className={`${isActive("/Home/Adds") ? "activeIcon" : ""}`} />
                        Ads
                    </div>
                </Link>
                <Link to="/Home/Contact_support" style={{ textDecoration: "none" }}>
                    <div className={`menu_button ${isActive("/Home/Contact_support") ? "activeRoute" : ""}`}>
                        <Bookings className={`${isActive("/Home/Contact_support") ? "activeIcon" : ""}`} />
                        Contact Support
                    </div>
                </Link>
                <Link to="/Home/Update_policy" style={{ textDecoration: "none" }}>
                    <div className={`menu_button ${isActive("/Home/Update_policy") ? "activeRoute" : ""}`}>
                        <Bookings className={`${isActive("/Home/Update_policy") ? "activeIcon" : ""}`} />
                        Update Policy
                    </div>
                </Link>
                <div className="logout" onClick={handleLogout}>
                    <img src={Exit} alt="" />
                    <div className="logText" >Log out</div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;
