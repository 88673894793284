import React, { useEffect, useState } from 'react';
import TopBar from '../Components/TopBar';
import './css/adds.css';
import Adds_Card from '../Components/Adds_Card';
import Modal from 'react-modal';
import { FileUploader } from "react-drag-drop-files";
import instance from '../utils/api';
import user_ads from '../assets/user_ads.svg';
import Loading from '../assets/Loading.svg';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const list = [
  { id: "1", name: "User adds" },
  { id: "2", name: "Service Provider adds" },
  { id: "3", name: "Deleted" }
];

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
    backgroundColor: '#ffffff',
    borderRadius: '6px'
  },
};

const fileTypes = ["JPG", "PNG", "GIF", "SVG"];

const Adds = () => {
  const [option, setOption] = useState("User adds");
  const [dialog, setDialog] = useState(false);
  const [data, setData] = useState([]);
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState("");
  const [isValid, setIsValid] = useState(null);
  const [isImgDisabled, setIsImgDisabled] = useState(false);
  const [isUrlDisabled, setIsUrlDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [category, setCategory] = useState('');
  const [disable,setDisable] = useState(false);
  const [submitDisable,setSubmitDisable] = useState(false);
  // console.log(category);

  const handleAds = async (type) => {
    try {
      setLoading2(true);
      const response = await instance.get(`api/Admin/getAdsData/${type}`);
      setData(response.data.adds);
    } catch (error) {
      console.log(error);
      toast.error(error.data.message);
    } finally {
      setLoading(false);
      setLoading2(false);
    }
  };

  const handleRemoveAds = async () => {
    try {
      setLoading2(true);
      const response = await instance.get('api/Admin/getRemoveAds');
      setData(response.data.adds);
    } catch (error) {
      console.log(error);
      toast.error(error.data.message);
      
    }finally{
      setLoading2(false);
    }
  };

  useEffect(() => {
    if (option === "Service Provider adds") {
      handleAds("Service Provider");
    } else if (option === "User adds") {
      handleAds("User");
    } else if (option === "Deleted") {
      handleRemoveAds();
    }
  }, [option]);

  const handleRemove = async (data,option) => {
    try {
      setDisable(true);
      const response = await instance.post('/api/Admin/updateAdsStatus', {
        add_id: data._id,
        status: !data.status
      });
      if (response.data.success) {
        console.log(response);
        console.log(data.status);
        console.log(option);
        toast.success(response.data.message);
        setDisable(false);
        if (data.status === true ) {
          handleAds(option);
        } else {
          handleRemoveAds();
        }
      } else {
        toast.error(response.data.message);
        setDisable(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setDisable(false);
    }
  };

  function isValidImageUrl(url) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  }

  const handleCheckImage = async () => {
    const result = await isValidImageUrl(url);
    setIsValid(result);
    setIsImgDisabled(result); // Disable image upload if URL is valid
  };

  const handleFileChange = (file) => {
    setFile(file);
    setIsUrlDisabled(!!file); // Disable URL input if an image is uploaded
  };

  const handleUrlChange = (e) => {
    const newUrl = e.target.value;
    setUrl(newUrl);
    setIsValid(null); // Reset the validation message
    setIsImgDisabled(!!newUrl); // Disable image upload if there is URL input
  };

  const handleRemoveImage = () => {
    setFile(null);
    setIsUrlDisabled(false); // Enable URL input when file is removed
  };

  const handleadds = async () => {
    try {
      if (!category) {
        return toast.error('Please select a category');
      }
      if (!file) {
        return toast.error('Please upload an image');
      }
      setSubmitDisable(true);
      const formData = new FormData();
      formData.append('ads', file);
      formData.append('category', category);
      const response = await instance.post('/api/Admin/createAdds', formData);
      console.log(response);

      if (response.data.success === true) {
        toast.success(response.data.message);
        setFile(null);
        setCategory('');
        setDialog(false);
        handleAds();
        setSubmitDisable(false);
      } else {
        toast.error(response.data.message);
        setSubmitDisable(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setSubmitDisable(false);
    }
  }

  return (
    <>
      {loading ? (
        <div className="loading-spinner">
          <img src={Loading} alt="loading" height={80} width={80} />
        </div>
      ) : (
        <div>
          <>
          <TopBar
            title={"Adds"}
            list={list}
            button={"Add Banners"}
            option={(e) => { setOption(e.target.getAttribute('name')) }}
            Active={option}
            onClick={() => { setDialog(true) }}
          />
          {loading2 ? (
        <div className="loading-spinner">
          <img src={Loading} alt="loading" height={80} width={80} />
        </div>
      ):
          <>
          {option === "User adds" && (
            <div className='Adds_cards'>
              {data.length ? data.map((data) => (
                <Adds_Card key={data._id} name={"Push ads"} Handleremove={() => { handleRemove(data,"User"); }} image={data.image} disable={disable} />
              )) : <div className='No_Adds_cards'> <img src={user_ads} alt='No Ads poster' /></div>}
            </div>
          )}
          {option === "Service Provider adds" && (
            <div className='Adds_cards'>
              {data.length ? data.map((data) => (
                <Adds_Card key={data._id} name={"Push ads"} Handleremove={() => { handleRemove(data,"Service Provider"); }} image={data.image} disable={disable} />
              )) : <div className='No_Adds_cards'> <img src={user_ads} alt='No Ads poster' /></div>}
            </div>
          )}
          {option === "Deleted" && (
            <div className='Adds_cards'>
              {data.length ? data.map((data) => (
                <Adds_Card key={data._id} remove={"Undo"} Handleremove={() => { handleRemove(data); }} image={data.image} disable={disable} />
              )) : <div className='No_Adds_cards'> <img src={user_ads} alt='No Ads poster' /></div>}
            </div>
          )}
          </>}
          </>
        </div>
      )}
      {dialog && (
        <Modal
          isOpen={dialog}
          onRequestClose={() => { setDialog(false) }}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className='Dialog_header'>
            <h1>Add Banner</h1>
            <p>To add Banner to the list, fill this following information</p>
          </div>
          <div className='Image_block'>
            <h3>Category<span className='label_required'>*</span></h3>
            <form className='radio_buttons'>
              <input type='radio' name='Category' onChange={() => { setCategory('User') }} /> <label>User Adds</label><br />
              <input type='radio' name='Category' onChange={() => { setCategory('Service Provider') }} /><label>Service Provider Adds</label>
            </form>
            <h3>Banner Image Upload<span className='label_required'>*</span></h3>
            <p>Add Your Image here</p>
            <FileUploader
              disabled={isImgDisabled}
              handleChange={handleFileChange}
              name="file"
              label="Browse or Drag your File(s)"
              types={fileTypes}
              classes='upload_button'
            />
            {/* {file && (
              <div>
                <p>File uploaded: {file.name}</p>
                <button onClick={handleRemoveImage}>Remove Image</button>
              </div>
            )} */}
            <p>Only support .jpg, .png, and .svg files</p>
            {/* <div className='URL'>
              <span className='add_URL_or'>or</span>
              <h3>Upload from URL</h3>
              <div className='button_url'>
                <input
                  type="text"
                  placeholder="Enter URL"
                  value={url}
                  name='url'
                  onChange={handleUrlChange}
                  disabled={isUrlDisabled}
                  className={isUrlDisabled ? 'disabled-field' : ''}
                />
                <button onClick={handleCheckImage}>Validate URL</button>
              </div>
              {isValid !== null && (
                <p>{isValid ? 'Valid image URL!' : 'Invalid image URL.'}</p>
              )}
            </div> */}
          </div>
          <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end', marginRight: '42px' }}>
            <button onClick={() => { setDialog(false) }} className='cancel'>Cancel</button>
            <button className='ADD' onClick={handleadds} disabled={submitDisable}>ADD</button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Adds;
