import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import instance from '../utils/api.js';
import { toast } from 'react-toastify';
import TopBar from '../Components/TopBar.js';

const list = [
    { id: "1", name: "User Policy" },
    { id: "2", name: "Service Provider Policy" }
]

const Policy = () => {
    const [policy, setPolicy] = useState("");
    const [terms, setTerms] = useState("");
    const [terms2, setTerms2] = useState("");
    const [sppolicy, setSPPolicy] = useState("");
    const [spterms, setSPTerms] = useState("");
    const [spterms2, setSPTerms2] = useState("");
    const [id, setid] = useState("");
    const [option, setOption] = useState("User Policy");


    const getPolicy = async () => {
        try {
            const response = await instance.get('/api//Admin/getpolicys');
            console.log(response.data);
            setPolicy(response.data.privicy_policy);
            setTerms(response.data.terms_conditions);
            setTerms2(response.data.terms_conditions_2);
            setSPPolicy(response.data.SP_privicy_policy);
            setSPTerms(response.data.SP_terms_conditions);
            setSPTerms2(response.data.SP_terms_conditions_2);
            setid(response.data._id);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getPolicy();
    }, [])

    const HandleSubmitterms = async () => {
        try {
            const response = await instance.put(`/api/Admin/updatePolicys/${id}`, {
                Terms_conditions: terms
            });
            // console.log(response);
            if (response.data.success === true) {
                toast.success(response.data.message);
                getPolicy();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message);
        }
    }
    const HandleSubmitpolicy = async () => {
        try {
            const response = await instance.put(`/api/Admin/updatePolicys/${id}`, {
                privicy_policy: policy
            });
            // console.log(response);
            if (response.data.success === true) {
                toast.success(response.data.message);
                getPolicy();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message);
        }
    }
    const HandleSubmitterms_2 = async () => {
        try {
            const response = await instance.put(`/api/Admin/updatePolicys/${id}`, {
                terms_conditions_2: terms2
            });
            // console.log(response);
            if (response.data.success === true) {
                toast.success(response.data.message);
                getPolicy();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message);
        }
    }
    const HandleSubmitpolicy_2 = async () => {
        try {
            const response = await instance.put(`/api/Admin/updatePolicys/${id}`, {
                SP_privicy_policy: sppolicy
            });
            // console.log(response);
            if (response.data.success === true) {
                toast.success(response.data.message);
                getPolicy();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message);
        }
    }
    const HandleSubmitterms_3 = async () => {
        try {
            const response = await instance.put(`/api/Admin/updatePolicys/${id}`, {
                SP_terms_conditions: spterms
            });
            // console.log(response);
            if (response.data.success === true) {
                toast.success(response.data.message);
                getPolicy();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message);
        }
    }
    const HandleSubmitterms_4 = async () => {
        try {
            const response = await instance.put(`/api/Admin/updatePolicys/${id}`, {
                SP_terms_conditions_2: spterms2
            });
            // console.log(response);
            if (response.data.success === true) {
                toast.success(response.data.message);
                getPolicy();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message);
        }
    }
    return (
        <div>
            <TopBar title={"Policy"} list={list} option={(e) => { setOption(e.target.getAttribute('name')) }} Active={option} />

            {option === "User Policy" ? (
                <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-between', width: '100%', height: '90vh', borderRadius: '12px', marginTop: '20px' }}>
                    <div style={{ width: '50%', padding: '20px', backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <h1 style={{ fontSize: '22px', color: '#555', textAlign: 'center', margin: '0px', marginBottom: '20px' }}>Privacy Policy</h1>
                        <Box
                            component="form"
                            sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                id="outlined-multiline-static"
                                label="Privacy Policy"
                                multiline
                                rows={16}
                                defaultValue="Enter your privacy policy here..."
                                sx={{ width: '100%', backgroundColor: '#F8F9FF', borderRadius: '8px' }}
                                value={policy}
                                onChange={(e) => setPolicy(e.target.value)}
                            />
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                <Button variant="contained" sx={{ padding: '10px 20px', fontSize: '16px', backgroundColor: "#F78E33" }} onClick={HandleSubmitpolicy}>Update</Button>
                            </div>
                        </Box>
                    </div>
                    <div style={{ width: '50%', padding: '20px', backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <h1 style={{ fontSize: '22px', color: '#555', textAlign: 'center', margin: '0px', marginBottom: '20px' }}>Terms and Conditions Login</h1>
                        <Box
                            component="form"
                            sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                id="outlined-multiline-static"
                                label="Terms and Conditions 1"
                                multiline
                                rows={16}
                                defaultValue="Enter your privacy policy here..."
                                sx={{ width: '100%', backgroundColor: '#F8F9FF', borderRadius: '8px' }}
                                value={terms}
                                onChange={(e) => setTerms(e.target.value)}
                            />
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                <Button variant="contained" sx={{ padding: '10px 20px', fontSize: '16px', backgroundColor: "#F78E33" }} onClick={HandleSubmitterms}>Update</Button>
                            </div>
                        </Box>
                    </div>
                    <div style={{ width: '50%', padding: '20px', backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <h1 style={{ fontSize: '22px', color: '#555', textAlign: 'center', margin: '0px', marginBottom: '20px' }}>Terms and Conditions Booking</h1>
                        <Box
                            component="form"
                            sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                id="outlined-multiline-static"
                                label="Terms and Conditions 2"
                                multiline
                                rows={16}
                                defaultValue="Enter your terms and conditions here..."
                                sx={{ width: '100%', backgroundColor: '#F8F9FF', borderRadius: '8px' }}
                                value={terms2}
                                onChange={(e) => setTerms2(e.target.value)}
                            />
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                <Button variant="contained" sx={{ padding: '10px 20px', fontSize: '16px', backgroundColor: "#F78E33" }} onClick={HandleSubmitterms_2}>Update</Button>
                            </div>
                        </Box>
                    </div>
                </div>
            ) : null}
            {option === "Service Provider Policy" ? (
                <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-between', width: '100%', height: '90vh', borderRadius: '12px', marginTop: '20px' }}>
                    <div style={{ width: '50%', padding: '20px', backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <h1 style={{ fontSize: '22px', color: '#555', textAlign: 'center', margin: '0px', marginBottom: '20px' }}>Privacy Policy</h1>
                        <Box
                            component="form"
                            sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                id="outlined-multiline-static"
                                label="Privacy Policy"
                                multiline
                                rows={16}
                                defaultValue="Enter your privacy policy here..."
                                sx={{ width: '100%', backgroundColor: '#F8F9FF', borderRadius: '8px' }}
                                value={sppolicy}
                                onChange={(e) => setSPPolicy(e.target.value)}
                            />
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                <Button variant="contained" sx={{ padding: '10px 20px', fontSize: '16px', backgroundColor: "#F78E33" }} onClick={HandleSubmitpolicy_2}>Update</Button>
                            </div>
                        </Box>
                    </div>
                    <div style={{ width: '50%', padding: '20px', backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <h1 style={{ fontSize: '22px', color: '#555', textAlign: 'center', margin: '0px', marginBottom: '20px' }}>Terms and Conditions Login</h1>
                        <Box
                            component="form"
                            sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                id="outlined-multiline-static"
                                label="Terms and Conditions 1"
                                multiline
                                rows={16}
                                defaultValue="Enter your privacy policy here..."
                                sx={{ width: '100%', backgroundColor: '#F8F9FF', borderRadius: '8px' }}
                                value={spterms}
                                onChange={(e) => setSPTerms(e.target.value)}
                            />
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                <Button variant="contained" sx={{ padding: '10px 20px', fontSize: '16px', backgroundColor: "#F78E33" }} onClick={HandleSubmitterms_3}>Update</Button>
                            </div>
                        </Box>
                    </div>
                    <div style={{ width: '50%', padding: '20px', backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <h1 style={{ fontSize: '22px', color: '#555', textAlign: 'center', margin: '0px', marginBottom: '20px' }}>Terms and Conditions Booking</h1>
                        <Box
                            component="form"
                            sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                id="outlined-multiline-static"
                                label="Terms and Conditions 2"
                                multiline
                                rows={16}
                                defaultValue="Enter your terms and conditions here..."
                                sx={{ width: '100%', backgroundColor: '#F8F9FF', borderRadius: '8px' }}
                                value={spterms2}
                                onChange={(e) => setSPTerms2(e.target.value)}
                            />
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                <Button variant="contained" sx={{ padding: '10px 20px', fontSize: '16px', backgroundColor: "#F78E33" }} onClick={HandleSubmitterms_4}>Update</Button>
                            </div>
                        </Box>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Policy;
