import React from 'react';
import background from '../assets/background.svg';
import cloud1 from '../assets/cloud1.svg';
import cloud2 from '../assets/cloud2.svg';
import man from '../assets/man.svg';
import service from '../assets/service.svg';
import './css/LoginComp.css';

const LoginComp = () => {
    return (
        <div className='Login_Component'>
            <img src={background} alt="background" className='background' />
            <img src={cloud1} alt="cloud1" className='cloud1' />
            <img src={cloud2} alt="cloud2" className='cloud2' />
            <img src={man} alt="man" className='man' />
            <img src={service} alt="service" className='service' />
        </div>
    )
}

export default LoginComp
