import React from 'react';
import './css/Topnav.css';
import bell from "../assets/bell.svg";
import profile from "../assets/profile.svg";

const Topnav = () => {
    return (
        <>
            <div className='Admin_TopNav'>
                <div className='Left_topNav'>
                    <div className='Name'>
                        <h1>Hi,Chandrahas!</h1>
                        <p>Let's check your progress today</p>
                    </div>
                </div>
                <div className='Right_TopNav'>
                    {/* <img src={bell} /> */}
                    <img src={profile} />
                    <div className='images'>
                        <h1>Chandrahas</h1>
                        <p>Admin</p>
                    </div>
                </div>
            </div>
            <div className='Linear_gradent'></div>
        </>
    )
}

export default Topnav
