import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import ForgotPassword from '../Pages/forgot_password';
import Login from '../Pages/Login';
import OtpVerification from '../Pages/otp_verification';
import NewPassword from '../Pages/New_password';
import Sidebar from '../Components/Sidebar';
import Home from '../Components/Home';
import Dashboard from '../Pages/Dashboard';
import Categories from '../Pages/Categories';
import AllCategories from '../Pages/All_Categories';
import Bookings from '../Pages/Bookings';
import Users from '../Pages/Users';
import Adds from '../Pages/adds';
import ContactSupport from '../Pages/Contact_support';
import ServiceProvider from '../Pages/Service_Provider';
import Payments from '../Pages/Payments';
import PaymentDetails from '../Pages/PaymentDetails';
import Update_policy from '../Pages/Policy.js';

const RoutesComponent = () => {
    const [triggerRefresh, setTriggerRefresh] = useState(false);

    const token = localStorage.getItem('token');
    const isAuthenticated = !!token; // Converts token to a boolean value

    return (
        <Routes>
            {isAuthenticated ? (
                <>
                    <Route path='/Home' element={<Home />}>
                        <Route path='Dashboard' element={<Dashboard />} />
                        <Route path='Categories' element={<Categories />} />
                        <Route path='Categories/Service_Providers/:id' element={<AllCategories />} />
                        <Route path='All_Categories' element={<AllCategories />} />
                        <Route path='Service_Provider' element={<ServiceProvider />} />
                        <Route path='Users' element={<Users />} />
                        <Route path='Bookings' element={<Bookings />} />
                        <Route path='Payments' element={<Payments />} />
                        <Route path='Payments/PaymentDetails/:id' element={<PaymentDetails />} />
                        <Route path='Adds' element={<Adds />} />
                        <Route path='Contact_support' element={<ContactSupport />} />
                        <Route path='Update_policy' element={<Update_policy />} />
                    </Route>
                    <Route path="*" element={<Navigate to="/Home/Dashboard" />} />
                </>
            ) : (
                <>
                    <Route path='/' element={<Login setTriggerRefresh={setTriggerRefresh} />} />
                    <Route path='/forgot_Password' element={<ForgotPassword />} />
                    <Route path='/Otp_verification' element={<OtpVerification />} />
                    <Route path='/New_password' element={<NewPassword />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </>
            )}
        </Routes>
    );
}

export default RoutesComponent;
